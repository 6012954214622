<div class="container-fluid projects-main">
    <div class="container title">
        <h1 class="filled-title">Proyectos</h1>
        <h1 class="outlined-title">Destacados</h1>
        <p>Algunos proyectos y soluciones que hemos desarrollado...</p>
    </div>

    <div class="row" style="justify-content: flex-end;">
        <div class="col-md-6 parallax-container">
            <!-- <div id="espacios" class="parallax-window" data-parallax="scroll" data-image-src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Foasis.png?alt=media&token=a93e9c9e-85af-451c-97cb-e279c38318c9" data-z-index="1" data-speed="0.5">
            </div> -->
            <div id="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Foasis.png?alt=media&token=a93e9c9e-85af-451c-97cb-e279c38318c9" appParallax [ratio]="-0.2">
            </div>
            <div class="description">
                <p><span>Oasis Hotels & Resorts</span> - Aplicación móvil para mejorar la experiencia del huésped
                </p>
            </div>
        </div>
        <div class="col-md-6 parallax-container" style="margin-top: 10rem; margin-bottom: 40px;">
            <div id="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Fnimboo.png?alt=media&token=58373a43-86fb-4480-a0a9-a062480ce0f4"appParallax [ratio]="-0.2">
            </div>
            <!-- <div id="espacios" class="parallax-window" data-parallax="scroll" data-image-src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Fnimboo.png?alt=media&token=58373a43-86fb-4480-a0a9-a062480ce0f4" data-z-index="1" data-speed="0.5">
            </div> -->
            <div class="description">
                <p><span>Nimboo</span> - Sitio web para portafolio de proyectos con imágenes de alta calidad.</p>
            </div>
        </div>
        <div class="col-md-6 parallax-container">
            <div id="image">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Fexcellence.png?alt=media&token=aa05bdd6-1e34-495d-83d1-a6000d61b2bd" appParallax [ratio]="-0.2">
            </div>
            <!-- <div id="espacios" class="parallax-window" data-parallax="scroll" data-image-src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/projects%2Fnimboo.png?alt=media&token=58373a43-86fb-4480-a0a9-a062480ce0f4" data-z-index="1" data-speed="0.5">
            </div> -->
            <div class="description">
                <p><span>Nimboo</span> - Sitio web para portafolio de proyectos con imágenes de alta calidad.</p>
            </div>
        </div>
    </div>


    <!-- <div class="container-fluid projects">
        <div class="row">
            <div class="col-12 col-xl-6 division-left">
                <div class="parallax">
                    <div class="parallax__layer parallax__layer--back">

                    </div>
                </div>
                <div class="col-12 description">
                    <p><span>Oasis Hotels & Resorts</span> - Aplicación móvil para mejorar la experiencia del huespued
                    </p>
                </div>
            </div>
            <div class="col-12 col-xl-6 division-right first-right">
                <div class="parallax">
                    <div class="parallax__layer parallax__layer--back2">

                    </div>
                </div>
                <div class="col-12 description">
                    <p><span>Nimboo</span> - Sitio web para portafolio de proyectos con imágenes de alta calidad.</p>
                </div>
            </div>
            <div class="col-12 col-xl-6 division-left">

            </div>
            <div class="col-12 col-xl-6 division-right">
                <div class="parallax">
                    <div class="parallax__layer parallax__layer--back3">

                    </div>
                </div>
                <div class="col-12 description">
                    <p><span>The Excelente Collection</span> - Aplicación móvil para hacer reservas en el hotel, servicio a la habitación, eventos y más.</p>
                </div>
            </div>
        </div>
    </div> -->

</div>