import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
;
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  locale: string = 'en';
  activeDay: boolean = false;
  viewChange = new EventEmitter<CalendarView>();
  viewDateChange = new EventEmitter<Date>();

  clickDate: Date;

  public load: boolean = true;
  clickedColumn: number;
  dates = [];
  newDate;
  disable = false;

  calendar = true;
  schedule = false;

  schedule_date;
  public calendars = [];
  public date_info = [];
  public available_dates = [];

  constructor(
    public calendarService: CalendarService) {
    this.dateChange()
    this.calendarService.getCurrentLan().subscribe(data => {
      this.locale = data.global;
    })
    
  }

  ngOnInit(): void {
  }

  clickedDate(event) {
    let arr = this.available_dates.filter(item => item.start.getDate() == event.getDate() &&
     item.start.getYear() == event.getYear() && item.start.getMonth() == event.getMonth())
     if(arr.length > 0){
      this.calendar = false;
      this.schedule_date = formatDate(event, 'yyyy-MM-ddTHH:mm:ss', 'es-MX');
      // console.log(this.schedule_date);
      this.schedule = true;
      // this.router.navigate(['schedules', date]);
     }
  }

  async dateChange() {
    this.load = true;
    this.disable = false;
    this.dates = [];
    this.available_dates = [];
    
    var date = new Date(this.viewDate);
    var dateNow = new Date(Date.now());
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    dateNow.setMilliseconds(0);
    dateNow.setSeconds(0);
    dateNow.setMinutes(0);
    dateNow.setHours(0);
    var primerDia;
    
    if(date.getTime() == dateNow.getTime()){
      this.disable = true;
       primerDia = new Date(this.viewDate);
       primerDia.setDate(this.viewDate.getDate()-1);
    }else{
       primerDia = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    
    var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let counter = 0;
    do {
      let newDate = primerDia.setDate(primerDia.getDate() + 1);
      let formatDate = new Date(newDate)
      if (counter == 0) {
        counter++;
        this.dates.push(formatDate)
      } else {
       
        formatDate.setMilliseconds(0);
        formatDate.setSeconds(0);
        formatDate.setMinutes(0);
        formatDate.setHours(0);
        this.dates.push(formatDate)
      } 
    } while (primerDia < ultimoDia);


    var calendars = await this.calendarService.getCalendars();
    const calendar = await Promise.all(calendars.map(async (calendars) => {
      const dates = await Promise.all(this.dates.map(async (dates) => {
        // var dates = await this.calendarService.getSpacesAvailables(calendars.id, dates);
        if (this.available_dates.length <= this.dates.length) {
          var dates = await this.calendarService.getSpacesAvailables(calendars.id, dates);
          dates.map((element) => {
            if (!this.available_dates.includes(element)) {
              this.available_dates.push({
                start: new Date(element.start),
                title: element.title,
                color: element.color
              })
            }
          })
        } else {
          // console.log(this.available_dates);
          
          return
        }
      }));
    }));

    this.events = this.available_dates;    
    this.load = false;

  }

  closeOverlay(item: boolean){
    this.schedule = item;
    this.calendar = !item;
    this.dateChange()
  }
}
