import { Component, Inject, OnInit } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { IParallaxScrollConfig } from 'ngx-parallax-scroll';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  loader=false;
  public isMobile: boolean = false;
  @Inject(PLATFORM_ID) private platformId: any

  constructor() { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // localStorage will be available: we can use it.
      var self = this;
      if (screen.width <= 768) {
        self.isMobile = true;
      } else {
        self.isMobile = false;
      }

    }
    setTimeout(() => {
      console.log('loaderrrrrrrrrrrrrr');
      
      this.loader = true
    }, 3000);
    
  }
}
