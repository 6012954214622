<section>
  <div class="container-fluid main" #main>
    <div class="col-12 closeButton">
      <a (click)="closeOverlayCalendar()">X</a>
    </div>
    <div class="col-12 content">
      <div class="row">
        <div class="col-12 col-lg-5 left container-text">
          <div>
            <h4>Nosotros te apoyamos</h4>
            <h1>Agenda tu asesoría</h1>
            <p>En nuestras <span>asesorías gratuitas</span> evaluamos tu proyecto y/o necesidad para
              identificar la solucion digital que se adaptará a tu necesidad</p>
          </div>
        </div>
        <div class="col-12 col-lg-7 right">
          <app-calendar></app-calendar>
        </div>
      </div>
    </div>
  </div>
</section>