import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import  * as firebase  from 'firebase/app';
import { HttpClient } from "@angular/common/http";

declare var gapi;
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  calendarRef: AngularFirestoreCollection<any>;
  itemDoc: AngularFirestoreDocument<any>;
  obsRange$: Observable<any>;
  language: Subject<any> = new Subject<any>();

  flag = "one";
  locale: string = 'en';

  API_KEY = "AIzaSyDeNVuBZgt3st62D5b3_mAEgb2xVRDS1F0";
  CLIENT_ID =  "164917744904-ba7bdneti31balb3v5g3ls2spkcvbkqu.apps.googleusercontent.com";
  CALENDAR_ID = "iolqtne4bjl7o6iq7gku8k0ghc@group.calendar.google.com";

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private http: HttpClient
  ) {

  }

  setCurrentLan(lang) {
    if (lang == 'es') {
      var data = {
        global: 'es-MX',
        collection: 'searched'
      }

      this.language.next(data);
    } else {
      var data = {
        global: 'en',
        collection: 'searchedEn'
      }

      this.language.next(data);
    }
  }

  getCurrentLan() {
    return this.language.asObservable();
  }

  getCalendars() {
    return new Promise<any>((resolve, reject) => {
      this.calendarRef = this.afs.collection("calendars")
      var obs$ = this.calendarRef.snapshotChanges().pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc['id'];
            return { id, ...data };
          });
        })
      );

      let sub = obs$.subscribe((res: any) => {
        resolve(res);
        // sub.unsubscribe();
      })
    });
  }

  getAvailableDatesFromCalendar(uid) {
    return new Promise<any>((resolve, reject) => {
      var today = new Date();
      var tomorrow = new Date();
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      var todayStr = today.toISOString();
      tomorrow.setDate(today.getDate() + 1)
      tomorrow.setHours(0)
      tomorrow.setMinutes(0)
      tomorrow.setSeconds(0)
      tomorrow.toISOString();
      tomorrow.setMilliseconds(0)
      var tomorrowStr = tomorrow.toISOString();

      this.calendarRef = this.afs.collection("calendars").doc(uid).collection("spaces", (ref) => ref.where('start', '>=', todayStr).where('start', '<', tomorrowStr));
      var obs$ = this.calendarRef.snapshotChanges().pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc['id'];
            return { id, calendarId: uid, ...data };
          });
        })
      );
      let sub = obs$.subscribe((data: any) => {
        console.log("DATA", data);

        resolve(data)
        sub.unsubscribe()
      })
    });
  }

  getSpacesAvailables(calendarId, begin) {
    return new Promise<any>((resolve, reject) => {
      var st = formatDate(begin, 'yyyy-MM-ddTHH:mm:ss', 'es-MX');

      var final = new Date(begin);
      final.setDate(begin.getDate() + 1);
      final.setHours(0, 0, 0, 0);

      var end = formatDate(final, 'yyyy-MM-ddTHH:mm:ss', 'es-MX');
      this.calendarRef = this.afs.collection("calendars").doc(calendarId).collection("spaces", (ref) => ref.where('title', '==', "disponible").orderBy('start').startAt(st)
        .endAt(end).limit(1));

      // this.calendarRef = this.afs.collection("calendars").doc(calendarId).collection("spaces", (ref) => ref.where('title', '==', "disponible").where('start', '>=', st)
      // .where('start', '<=', end));

      var obs$ = this.calendarRef.snapshotChanges().pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc['id'];
            return { id, calendarId: calendarId, ...data };
          });
        })
      );
      let sub = obs$.subscribe((data: any) => {
        resolve(data)
        sub.unsubscribe()
      })
    });
  }


  getSchedules(calendarId, day) {
    return new Promise<any>((resolve, reject) => {
      console.log(day);

      var today = new Date(day);
      var tomorrow = new Date(day);
      tomorrow.setDate(today.getDate() + 1);
      tomorrow.setHours(0)
      tomorrow.setMinutes(0)
      tomorrow.setSeconds(0)
      tomorrow.setMilliseconds(0)

      var day2 = formatDate(tomorrow, 'yyyy-MM-ddTHH:mm:ss', 'es-MX');

      this.calendarRef = this.afs.collection("calendars").doc(calendarId).collection("spaces", (ref) => ref.where('title', '==', "disponible").where('start', '>=', day).where('start', '<', day2));
      var obs$ = this.calendarRef.snapshotChanges().pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as {};
            const id = a.payload.doc['id'];
            return { id, calendarId: calendarId, ...data };
          });
        })
      );
      let sub = obs$.subscribe((data: any) => {
        console.log(data, "respuesta bd");
        
        resolve(data)
        sub.unsubscribe()
      })
    });
  }

  updateSpace(userM, spaceId, calId, data, comments, idEventGC) {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection('calendars')
        .doc(calId)
        .collection('spaces')
        .doc(spaceId)
        .update({
          title: 'reservado',
          color: '#1aafd5',
          comments: comments,
          extendedProps: {
            status: 1,
            userModified: userM,
            clientId: "",
            client: data,
            updateAt: new Date(),
            eventIdGC: idEventGC,
          },
        })
        .then((res) => resolve(res));
    });
  }

  getSpace(spaceId, calId) {
    return new Promise<any>((resolve, reject) => {
      let ref = this.afs
        .collection('calendars')
        .doc(calId)
        .collection('spaces')
        .doc(spaceId).get();

        let sub = ref.subscribe(
          (data) => {
              resolve(data);  
            sub.unsubscribe();
          },
          (e) => {
          }
        );
    });
  }

  initClient(){
    gapi.load('client', () => {
      console.log('loaded client')
    
      gapi.client.init({
        apiKey: this.API_KEY,
        clientId: this.CLIENT_ID,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: 'https://www.googleapis.com/auth/calendar'
      })
    })
  }

  async login() {
    const googleAuth = gapi.auth2.getAuthInstance()
    const googleUser = await googleAuth.signIn();
  
    const token = googleUser.getAuthResponse().id_token;
  
    const credential = firebase.default.auth.GoogleAuthProvider.credential(token);
  
    await this.afAuth.signInAndRetrieveDataWithCredential(credential);
  }


  async addCalendarEventAPI(event:any) {    
    const headers = { 'Authorization': 'Bearer ai5kaWF6QGRhcHBlcnRlY2hub2xvZ2llcy5jb206REFwcGVyTWVuc2FqZWRlQXV0ZW50aWNhY2lvbjEyMzE=' }
    const response = await this.http.post<{code:string, message:string, idGCEvent:string}>("https://back-dapper.herokuapp.com/add-event", event, { headers }).toPromise()
    return response;
  }
}