import { Component, Inject, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
// import { TimelineMax } from 'gsap';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  private wowSubscription: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router, 
    private wowService: NgwWowService) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          console.log('algo');
          if (isPlatformBrowser(this.platformId)) {
            this.wowService.init();
          }
        }
        // Reload WoW animations when done navigating to page,
        // but you are free to call it whenever/wherever you like
      });
    }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // unsubscribe (if necessary) to WOW observable to prevent memory leaks
      this.wowSubscription.unsubscribe();
    }
  }

}
