<div class="main" *ngIf="schedules == true" id="books">
    <div class="welcome">
        <h5>Horarios disponibles del <span>{{ dateStr }}</span></h5>
        <p class="my-3">* Si necesita una cita fuera del horario habitual, comuníquese con nosotros. </p>
        <div class="row scroll">
            <ng-container *ngFor="let calendar of schedule | keyvalue">
                <div class="col-md-12 col-xl-12" *ngIf="calendar.value.schedules.length > 0">
                    <div class="row">
                        <div class="col-md-12 col-xl-12 mt-lg-2">
                            <h5>{{calendar.value.name}}</h5>
                            <div class="row" >
                                <ng-container *ngFor="let time of calendar.value.schedules">
                                    <button class="btn-sche btn mx-1 my-2" style="font-size: .9rem;" id="{{time.id}}" (click)="goToDate(time)" >{{time.start | date: 'hh:mm aa'}}</button>
                                </ng-container>
                            </div>
                            <div *ngIf="calendar.value.schedules.length < 0">
                                <p>No hay citas disponibles.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
        </div>
        <div class="buttons mt-5">
            <button class="btn btn-back" (click)="redirect()">Volver</button>
        </div>
    </div>
</div>

<app-form-appointment *ngIf="form == true" [dateId]="id" [date]="date_selected" [calId]="calId" (noOverlay)="closeOverlay($event)" (closeAll)="closeAll($event)"></app-form-appointment>

<!-- <app-client-info *ngIf="client == true" [dateId]="id" [calId]="calId"></app-client-info> -->
<!-- <app-client-login *ngIf="client == true" [dateId]="id" [calId]="calId"></app-client-login> -->

<!-- <app-confirmation *ngIf="confirmation == true" [dateId]="id" [idCal]="calId" [uid]="uid" [clientName]="name"
[clientEmail]="email" [clientPhone]="phone"></app-confirmation> -->