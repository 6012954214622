
       <div class="container-fluid banner-home row">
        <!-- wow animate__animated animate__fadeIn -->
        <div class="col-12 col-lg-6 left wow animate__animated animate__fadeIn" >
            <div class="col-md-12 info">
                <div class="col-12">
                    <h4>Empresa No. 1 en</h4>
                </div>
                <div class="col-12">
                    <h1>Desarrollo de software</h1>
                </div>
                <div class="col-12 description">
                    <p>Somos una empresa especializada en el análisis y desarrollo 
                        de soluciones tecnológicas integrales, enfocadas al <span>incremento 
                        en las utilidades</span> de nuestros clientes. </p>
                </div>
                <div class="col-12 btn-slider">
                    <a class="btn btn-primary" href="#contact">Hora de comenzar</a>
                </div>
            </div>
        </div>
        <!-- wow animate__animated animate__fadeIn -->
        <div class="col-12 col-lg-6 image-cont wow animate__animated animate__fadeIn">
           
            <div class="col-12 image" >
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/laptop%20(2).png?alt=media&token=aa2acc38-3d31-479e-b7b8-1dec191bf690" alt="">
            </div>
            <div class="col-12 social">
                <ul class="float-right">
                    <li><a href="https://www.facebook.com/Dapper-Technologies-1210594148973743/?view_public_for=1210594148973743" target="_blank"><img src="../../../assets/images/icons/facebook.svg" alt=""></a></li>
                    <li><a href="https://www.instagram.com/dapper.tech/?hl=es-la" target="_blank"><img src="../../../assets/images/icons/instagram.svg" alt=""></a></li>
                    <li><a href="https://www.linkedin.com/company/dapper-technologies/?viewAsMember=true" target="_blank"><img src="../../../assets/images/icons/linkedin.svg" alt=""></a></li>
                    <li><a href="https://twitter.com/_DapperTech" target="_blank"><img src="../../../assets/images/icons/twitter.svg" alt=""></a></li>
                </ul>
            </div>
           
            <div class="col-12 slider-controls" hidden>
                <div class="row align-items-center">
                    <div class="col-4 pages">
                        <label for=""><span>01</span> | 03</label>
                    </div>
                    <div class="col-4 dots">
                        <label for=""> - - - </label>
                    </div>
                    <div class="col-4 btn-control">
                        <button nbButton>Siguiente <nb-icon icon="arrow-forward-outline"></nb-icon></button>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
