import { Component, Inject } from '@angular/core';
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TweenMax } from 'gsap/dist/gsap';
import { ExpoScaleEase } from 'gsap/all';
import { Power2 } from 'gsap';
import { CalendarService } from './services/calendar/calendar.service';
  



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'new-dapper-web';
  public isMobile: boolean = false;
  
  constructor(@Inject(PLATFORM_ID) private platformId: any,
  private calendarService: CalendarService) {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.registerPlugin(ExpoScaleEase);
    }

    this.calendarService.setCurrentLan("es");

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // localStorage will be available: we can use it.
      var self = this;
      if (screen.width <= 1024) {
        self.isMobile = true;
      } else {
        self.isMobile = false;
      }
    }
    
    if (!this.isMobile) {
      if (isPlatformBrowser(this.platformId)) {
        //Slider animation
        gsap.to(".slider-comp", {
          scrollTrigger: {
            trigger: ".about-comp",
            start: "top 90%",
            end: "top 20%",
            scrub: true,
            // markers: true
          },
          ease: "power2.out",
          duration: 1,
          opacity: 0,
          y: 30
        });
      }

      if (isPlatformBrowser(this.platformId)) {
        // About enter animation
        gsap.to(".about-comp", {
          scrollTrigger: {
            trigger: ".about-comp",
            start: "top center",
            end: "300px center",
            toggleActions: "start reverse restart reverse",
            scrub: true,
            // markers: {
            //   startColor: "salmon",
            //   endColor: "pink",
            //   fontSize: "2rem"
            // }
          },
          ease: "power2.in",
          duration: 1,
          opacity: 1,
          y: 0
        });
      }

      

      // if (isPlatformBrowser(this.platformId)) {

      //   let imageZoom = gsap.timeline({
      //     scrollTrigger: {
      //       trigger: ".image-comp",
      //       start: "top top",
      //       end: "center top",
      //       pin: true,
      //       scrub: true,
      //       markers: {
      //           startColor: "salmon",
      //           endColor: "pink",
      //           fontSize: "2rem"
      //         }
      //     }
      //   });

      //   imageZoom.fromTo(".image-comp", 20, {scale:150, opacity: 0, translateY: -20050}, {scale:1, opacity: 1, translateY: 0, ease:ExpoScaleEase.config(20, 1), repeat:0})
        
      // }

    }


  }
}


