import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective {

  @Input('ratio') parallaxRatio: number = 1
  initialTop: number = 0
  screenWidth = window.innerWidth;

  constructor(private eleRef: ElementRef) {
    // this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top
  }

  @HostListener("window:scroll", ["$event"])

  
  onWindowScroll(event) {
     
    console.log(this.screenWidth);
    
    
    // if (this.screenWidth < 1800) {
        this.eleRef.nativeElement.style.top = ((this.initialTop -850 ) - (window.scrollY * this.parallaxRatio)) + 'px'
        
    // }

    // if (this.screenWidth < 1500) {
    //     this.eleRef.nativeElement.style.top = ((this.initialTop -760 ) - (window.scrollY * this.parallaxRatio)) + 'px'
        
    // }
      
  }

}
