import { Component, Inject, OnInit } from '@angular/core';
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public isMobile: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger);
    }

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // localStorage will be available: we can use it.
      var self = this;
      if (screen.width <= 1024) {
        self.isMobile = true;
      } else {
        self.isMobile = false;
      }
    }

    if (!this.isMobile) {
      if (isPlatformBrowser(this.platformId)) {
        // services enter animation
        gsap.to(".services", {
          scrollTrigger: {
            trigger: ".services",
            start: "top 80%",
            end: "top 20%",
            toggleActions: "start reverse restart reverse",
            scrub: true,
            // markers: {
            //   startColor: "salmon",
            //   endColor: "pink",
            //   fontSize: "2rem"
            // }
          },
          ease: "power2.in",
          duration: 1,
          opacity: 1,
          y: 0
        });
      }
    }
  }

}
