<div class="main" *ngIf="form == true" id="books">
    <div class="welcome">
        <h4 id="space-title">Espacio disponible <span id="date-time">{{date.start | date: 'HH:mm'}} hrs - {{date.end |
                date: 'HH:mm'}} hrs</span></h4>
        <div class="row">
            <form action="" [formGroup]="dateForm" style="width: 100%;">
                <div class="form-group custom-form-group">
                    <label for="">Cliente</label>
                    <input type="text" placeholder="Nombre cliente" class="input-search-nebular btn-actions-b"
                        formControlName="user">
                    <div class="white-space">
                        <ng-container *ngIf="dateForm.get('user').errors && dateForm.get('user').touched">
                            <span *ngIf="dateForm.get('user').hasError('required')" class="text-danger"> El
                                'Cliente' es
                                requerido.</span>
                        </ng-container>
                    </div>
                    <label for="">Correo electrónico</label>
                    <input type="text" placeholder="Correo cliente" class="input-search-nebular btn-actions-b"
                        formControlName="email">
                    <div class="white-space">
                        <ng-container *ngIf="dateForm.get('email').errors && dateForm.get('email').touched">
                            <span *ngIf="dateForm.get('email').hasError('required')" class="text-danger"> El 'Email'
                                es requerido.</span>
                            <span *ngIf="dateForm.get('email').hasError('pattern')" class="text-danger"> El formato
                                del correo es
                                inválido.</span>
                        </ng-container>
                    </div>
                    <label for="">Teléfono</label>
                    <input type="text" placeholder="Telefono cliente" class="input-search-nebular btn-actions-b"
                        formControlName="phone">
                    <div class="white-space"></div>
                    <label for="">Comentarios</label>
                    <textarea placeholder="Comentarios" rows="6" class="custom-text-area" formControlName="comments"
                        #comments></textarea>
                </div>
            </form>
        </div>
        <div class="buttons">
            <div class="float-left" style="margin-left:20px; margin-bottom: 10px;">
                <input class="float-left mt-1 mr-1" type="checkbox" checked id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    Me gustaría recibir noticias y ofertas especiales de Dapper Technologies.
                </label>
            </div>
            <button class="btn btn-back float-right mx-2 round" (click)="redirect();clearInput()"
                id="cancel-btn">Cancelar</button>
            <button status="primary" class="btn btn-succes float-right mx-2 round"
                (click)="updateSpaceSubmit(date.id);clearInput()" [disabled]="dateForm.invalid"
                id="generate-btn">Generar
                reserva</button>
        </div>
    </div>
</div>