<section>
    <div class="container-fluid main">
        <div class="col-12 title">
            <h1>Clientes que han<br>confiado en nosotros</h1>
        </div>
        <div class="col-12 logos">
            <div class="row align-items-center">
                <!-- First Row -->
                <div class="col-6 col-lg-4 col-xl-3 client-logo" id="oasis">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Foasislogo.png?alt=media&token=cdb683d4-08ce-4d20-9c36-61b6c60220fc" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo" id="excellence">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fexcellence.jpg?alt=media&token=62abbab4-d2e9-4532-9a85-ac5eae1105f0" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo" id="single">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fsingle.png?alt=media&token=67dccb77-a90d-410d-8fbc-e98fe35d26b5" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo" id="mia">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fmia.png?alt=media&token=cde88d00-cfab-4b79-954c-4eed50398317" alt="">
                </div>
                <!-- Second Row -->
                <div class="col-6 col-lg-4 col-xl-3 client-logo middle" id="monstruo">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fmonstruo.png?alt=media&token=cc542d1b-93da-4220-9ecb-e238ccaec3f7" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo middle" id="tangente">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Ftangente.png?alt=media&token=ca10258f-e886-4ee3-9646-3274949b85d9" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo middle" id="nimboo">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fnimboologo.png?alt=media&token=f1abc49a-c94f-4475-9314-cc41b35efa6d" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo middle" id="acero">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2FaceroCC.png?alt=media&token=e6c8d65d-ee5e-47cb-ad04-27afe3307fb5" alt="">
                </div>
                <!-- Third Row -->
                <div class="col-6 col-lg-4 col-xl-3 client-logo down" id="wsi">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fwsi.png?alt=media&token=b5198be6-062d-400e-8238-1c8c8053a0fd" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo down" id="chacchi">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fchac-chi.png?alt=media&token=11e011f3-0b63-43dc-a43b-4791e266735b" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo down" id="lasalle">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fla-salle.png?alt=media&token=3612c63c-e921-4cf4-9b67-c96d61f0b188" alt="">
                </div>
                <div class="col-6 col-lg-4 col-xl-3 client-logo down" id="piesano">
                    <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/clients%2Fpie-sano.png?alt=media&token=5f3c24bb-e834-43b8-ab28-9b12dbd3fb30" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="col-12 logos-down">
            <div class="row align-items-center">
                
            </div>
        </div> -->
    </div>
</section>
