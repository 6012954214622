<section id="services">
    <div class="title">
        <h2>
            Servicios
        </h2>
    </div>
    <div class="container-service">
        <div class="row-service">
            <div class="service col-service-1 col-mid-1 report">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fposicionamiento%2Freport.png?alt=media&token=91716ee8-5b96-4cda-b0b6-08738a8022e3" alt="">
                <div class="text">
                    Posicionamiento Web y SEO
                </div>
            </div>
            <div class="service col-service-2 col-mid-2">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fdesarrollo%2FDesktop.png?alt=media&token=7812c38f-edcf-406a-ab1e-b8f676723ba1" alt="">
                <div class="text bg-linear">
                    Desarrollo Web a la Medida
                </div>
            </div>
            <div class="service col-service-3 aproanza col-mid-2">
                <img id="logo-app" src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fmoviles%2Fapp.png?alt=media&token=d1efb008-f519-4832-81a4-876b180dbed3" alt="">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fmoviles%2Fphone.png?alt=media&token=8fa63106-a559-48b8-a657-0f5351c62180" alt="">
                <div class="text">
                    Desarrollo de Aplicaciones para Hoteles
                </div>
            </div>
            <div class="service col-service-3 col-mid-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fmoviles%2Fphone.png?alt=media&token=8fa63106-a559-48b8-a657-0f5351c62180" alt="">
                <div class="text">
                    Desarrollo de Software a la Medida
                </div>

            </div>
            <div class="service col-service-2 nino col-mid-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fecommerce%2FDEsktop.png?alt=media&token=c8a6eb31-f750-4aef-b8e1-98864251f22d" alt="">
                <div class="text">
                    Desarrollo de E-Commerce
                </div>
            </div>
            <div class="service col-service-1 col-mid-2">
                <img src="https://firebasestorage.googleapis.com/v0/b/dapper-page.appspot.com/o/services%2Fposicionamiento%2Freport.png?alt=media&token=91716ee8-5b96-4cda-b0b6-08738a8022e3" alt="">
                <div class="text  bg-linear">
                    Desarrollo de Apps
                </div>

            </div>
        </div>
    </div>
</section>