<nb-layout>

  <!-- <nb-layout-header fixed>
  </nb-layout-header> -->

  <nb-layout-column>
    <app-header></app-header>
    <div class="slider-comp" id="home">
      <app-home-slider></app-home-slider>
    </div>
    <div class="about-comp" id="about">
      <app-about-us></app-about-us>
    </div>
    <!-- <div class="image-comp">
      <app-image-zoom></app-image-zoom>
    </div> -->
    <div class="projects-comp" id="projects">
      <app-projects></app-projects>
    </div>
    <!-- <app-prueba></app-prueba> -->
    <div class="clients-comp" id="clients">
      <app-clients></app-clients>
    </div>
    <div class="services-comp" id="services">
      <app-services></app-services>
    </div>
    <div class="next-comp">
      <app-next-step></app-next-step>
    </div>
<!-- <app-custom-calendar></app-custom-calendar> -->


    <div class="footer-comp" id="contact">
      <app-footer></app-footer>
    </div>
    
    <!-- <router-outlet></router-outlet> -->
  </nb-layout-column>

  <!-- <nb-layout-footer fixed>
  </nb-layout-footer> -->

</nb-layout>
