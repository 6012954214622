import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'scrollable-tabs',
  templateUrl: './scrollable-tabs.component.html',
  styleUrls: ['./scrollable-tabs.component.scss']
})
export class ScrollableTabsComponent implements OnInit {

  @Input() tabs;
  selectedIndex = 0;
  abc: string;
  leftTabIdx = 0;
  atStart = true;
  atEnd = false
  @Output() emitSelectedTab = new EventEmitter()
  constructor() { }

  ngOnInit() {
    this.emitSelectedTab.emit(this.tabs[0])
    this.abc = `translateX(0px)`
  }

  selectTab(index) {
    this.selectedIndex = index
    this.emitSelectedTab.emit(this.tabs[index])
    this.scrollTab(index-this.leftTabIdx)
  }

  scrollTab(x) {
    if (this.atStart && x < 0 || this.atEnd && x > 0) {
      return
    }
    this.leftTabIdx = this.leftTabIdx + x
    this.abc = `translateX(${(this.leftTabIdx) * -210}px)`
    this.atStart = this.leftTabIdx === 0
    this.atEnd = this.leftTabIdx === this.tabs.length -1
  }

}
