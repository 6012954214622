import { Component, Inject, OnInit } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  @Inject(PLATFORM_ID) private platformId: any
  // public isMobile: boolean = false;
  public selected_what: boolean = false;
  public selected_how: boolean = true;
  public selected_why: boolean = false;
  public tabs = ['¿Qué hacemos?', '¿Cómo lo hacemos?', '¿Por qué nosotros?']
  public tab: string;
  public stepToShow: number = 1;

  constructor() { }

  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   console.log(this.isMobile);
    //   // localStorage will be available: we can use it.
    //   var self = this;
    //   if (screen.width <= 768) {
    //     self.isMobile = true;
    //   } else {
    //     self.isMobile = false;
    //   }
    // }
  }

  selectedTab(e) {
    this.tab = e
    console.log(e);
    switch (this.tab) {
      case "¿Qué hacemos?":
        this.selected_what = true;
        this.selected_how = false;
        this.selected_why = false;
        break;
      case "¿Cómo lo hacemos?":
        this.selected_what = false;
        this.selected_how = true;
        this.selected_why = false;
        break;
      case "¿Por qué nosotros?":
        this.selected_what = false;
        this.selected_how = false;
        this.selected_why = true;
        break;
    
      default:
        break;
    }
  }

  getStepToShow(step){
    this.stepToShow = step;
  }


}
