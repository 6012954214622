import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-appointment',
  templateUrl: './form-appointment.component.html',
  styleUrls: ['./form-appointment.component.scss']
})
export class FormAppointmentComponent implements OnInit {

  @Input() date: any; 
  @Output() noOverlay = new EventEmitter<boolean>(); 
  @Output() closeAll = new EventEmitter<boolean>(); 
  //forms
  dateForm: FormGroup;

  //ids
  recepName = "XUwmWzsRMkj1mWVHTkhk";

  form = true;  
  dateStr;

  constructor(
    private formBuilder: FormBuilder,    
    public calendarService: CalendarService, 
  ) {
    this.dateForm = this.formBuilder.group({
      user: ['', Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$")]],
      phone: [""],
      comments: ['']
    });
  }

  ngOnInit(): void {
    console.log(this.date);
    this.getDate();
  }

  getDate() {
    var newDate = new Date(this.date);

    this.dateStr = newDate.toLocaleDateString('es-MX', { weekday: "long", year: "numeric", month: "long", day: "numeric" });
  }

  redirect() {
    this.noOverlay.emit(false);
  }

  redirectCalendar(){
    this.closeAll.emit(false);
  }

  //
  // Update Space
  //
  async updateSpaceSubmit(uid) {
    Swal.fire({
      title: 'Guardando ...',
      showConfirmButton:false,
      willOpen(){
        Swal.showLoading();
      },
      willClose(){
        Swal.hideLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    await this.updateSpace(uid);
    Swal.close();
    this.redirectCalendar()
  }

  async updateSpace(spaceid) {
    var form = this.dateForm.value;   
    var comms = form.comments;

    var client = {
      id: null,
      name: form.user,
      email: form.email,
      phone: form.phone,
    }

    var event = {
      summary: `Cita con ${form.user}`,
      comments: comms,
      start: this.date.start,
      end: this.date.end
    }

    var response = await this.calendarService.addCalendarEventAPI(event);
    console.log(response);

    this.calendarService.updateSpace(this.recepName, this.date.id, this.date.calendarId, client, comms, response.idGCEvent).then((res) => {
      console.log(res);
      Swal.fire({
        title: '¡Excelente!',
        text: 'La cita se ha creado correctamente',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
    });    
    
  }

  clearInput(){
    this.dateForm.controls.user.setValue("");
    this.dateForm.controls.email.setValue("");
    this.dateForm.controls.phone.setValue("");
    this.dateForm.controls.comments.setValue("");
  }

  cleanForm() {
    this.dateForm.reset()
  }
}
