import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss']
})
export class CustomCalendarComponent implements OnInit {

  @Input() overlay: boolean; //Boolean value to open and close the overlay coming from footer component
  @Output() noOverlay = new EventEmitter<boolean>(); // Send an event with a boolean value to close de overlay
  @ViewChild ('main') main: HTMLElement;

  constructor() { }

  ngOnInit(): void {
  }
  closeOverlayCalendar() {
    this.noOverlay.emit(false);
  }
}
