<section>
    <div class="container-fluid main" #main>
        <div class="col-12 closeButton">
            <a (click)="closeOverlay()">X</a>
        </div>
        <div class="col-12 content">
            <div class="row">
                <div class="col-12 col-lg-5 left">
                    <div class="col-12 text">
                        <h4>Es nuestro placer poder darte la</h4>
                        <h1>Bienvenida al futuro</h1>
                        <p>Transforma tu negocio con nuestras soluciones de automatización y optimización que te
                            generarán un <span> incremento directo en tus utilidades.</span></p>
                    </div>
                </div>
                <div class="col-12 col-lg-7 right">
                    <div class="col-12">
                        <p style="color: #fff;">Agenda tu asesoría GRATUITA</p>
                    </div><br>
                    <div class="col-12">
                        <form [formGroup]="contactForm">
                            <div class="row">
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="name" class="form-control contact-home-input"
                                        formControlName="name" aria-describedby="nameHelpInline"
                                        placeholder="Primer nombre">
                                </div>
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="lastName" class="form-control contact-home-input"
                                        formControlName="lastName" aria-describedby="lastNameHelpInline"
                                        placeholder="Apellidos">
                                </div>
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="email" class="form-control contact-home-input"
                                        formControlName="email" aria-describedby="emailHelpInline" placeholder="Correo">
                                </div>
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="phone" class="form-control contact-home-input"
                                        formControlName="phone" placeholder="Teléfono" appPhoneMask>
                                </div>
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="enterprise" class="form-control contact-home-input"
                                        formControlName="enterprise" aria-describedby="enterpriseHelpInline"
                                        placeholder="Empresa">
                                </div>
                                <div class="col-12 col-md-6 form-group mt-4">
                                    <input type="text" id="country" class="form-control contact-home-input"
                                        formControlName="country" aria-describedby="countryHelpInline"
                                        placeholder="País">
                                </div>
                                <div class="col-12 col-md-6 col-lg-12 col-xl-6 form-group mt-4">
                                    <input type="text" id="message" class="form-control contact-home-input"
                                        formControlName="message" aria-describedby="nameHelpInline"
                                        placeholder="Solución que estás buscando">
                                </div>
                                <div class="col-12 col-md-6 col-lg-12 col-xl-6 form-group mt-4">
                                    <re-captcha formControlName="captcha" name="captcha" [siteKey]="siteKey" (resolved)="resolved($event)"
                                    (error)="onError($event)" theme="dark">
                                    </re-captcha>
                                </div>
                            </div>
                            <div *ngIf="contactForm.get('email').errors && contactForm.get('email').touched"
                                class="col-md-12 form-error my-2">
                                <p *ngIf="contactForm.get('email').hasError('pattern')"> El formato del correo es
                                    inválido.</p>
                            </div>
                            <div class="col-md-12 form-error">
                                <p *ngIf="hasError">
                                    Todos los campos y la verificación de captcha son obligatorios.
                                </p>
                                <p *ngIf="captchaError">
                                    Error de verificación, espere unos segundos e intente de nuevo.
                                </p>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="successAlert"><i class="fa fa-check"></i>Se ha enviado el correo. Gracias por
                                    contactarnos.</p>
                                <p *ngIf="errorAlert"><i class="fa fa-times"></i>Hubo un error. Inténtalo de nuevo por
                                    favor.</p>
                            </div>

                            <div class="col-12 col-md-6 btn-slider">
                                <button class="btn btn-primary" *ngIf="!loader" (click)="register()" [disabled]="!contactForm.valid">Hora de
                                    comenzar</button>
                                <button class="btn btn-primary" *ngIf="loader" style="padding: 4% 36%;">
                                    <mat-spinner diameter=35 color=#ffffff></mat-spinner>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>