import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isOverlay: boolean = false;
  public isOverlayCalendar: boolean = false;

  constructor() { }

  ngOnInit(): void {

    console.log(this.isOverlay, this.isOverlayCalendar);
    
  }

  closeOverlay(item: boolean){
    this.isOverlay = item;
  }

  closeOverlayCalendar(item: boolean){
    this.isOverlayCalendar = item;
  }

}
