<div class="container-fluid header">
    <div class="row align-items-center">
        <div class="col-6 col-lg-4 logo">
            <img src="../../../assets/images/logo/logo.png" alt="">
        </div>
        <div class="col-6 col-lg-8 menu isDesktop">
            <nav>
                <ul>
                    <li><a href="#about">Nosotros</a></li>
                    <li><a href="#services">Servicios</a></li>
                    <li><a href="#projects">Proyectos</a></li>
                </ul>
            </nav>
        </div>

        <div class="col-6 dropdown isMobile">
            <button type="button" class="btn-mobile-menu dropbtn"
                (click)="showMobileMenu = !showMobileMenu"><i class="fa fa-equals"></i></button>
            <div class="dropdown-content" *ngIf="showMobileMenu">
                <a href="#about">Nosotros</a>
                <a href="#services">Servicios</a>
                <a href="#projects">Proyectos</a>
            </div>
        </div>
    </div>
</div>