<div class="container" style="padding: 0 !important;">
  <div *ngIf="calendar == true" id="books">
    <div class="welcome" *ngIf="load">
      <div class="col-12" style="text-align: center;">
        <img src="" alt="">
      </div>
      <div class="col-12 p-5" style="text-align: center;">
        <h5>Buscando espacios disponibles </h5>
        <h6>Esto puede tardar unos minutos.</h6>
      </div>
      <div class="col-12 my-5">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="welcome" *ngIf="!load">
      <div class="pl-5">
        <button class="btn" mwlCalendarToday [(viewDate)]="viewDate" (click)="dateChange()"
          (viewDateChange)="viewDateChange.next(viewDate)">
          Hoy
        </button>
      </div>
      <div class="row text-center align-items-center">
        <div class="col-md-2 btn-header">
          <div class="btn-group">
            <button *ngIf="disable == false" class="btn large btn-arrow" mwlCalendarPreviousView [view]="view"
              [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)" (click)="dateChange()">
              <b><i class="fa fa-chevron-left" aria-hidden="true"></i></b>
            </button>
          </div>
        </div>
        <div class="col-md-8">
          <h3>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
        </div>
        <div class="col-md-2 btn-header">
          <button class="btn large btn-arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)" (click)="dateChange()">
            <b><i class="fa fa-chevron-right" aria-hidden="true"></i></b>
          </button>
        </div>
      </div>
      <br />
      <div class="col-12">
        <mwl-calendar-month-view [locale]="locale" [viewDate]="viewDate" [events]="events"
          (columnHeaderClicked)="clickedColumn = $event.isoDayNumber" (dayClicked)="clickedDate($event.day.date)"
          weekStartsOn="1">
        </mwl-calendar-month-view>
      </div>
    </div>
  </div>

  <app-appointments *ngIf="schedule == true" [date]="schedule_date" (noOverlay)="closeOverlay($event)">
  </app-appointments>
</div>