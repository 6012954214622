import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { EmailService } from 'src/app/services/email/email.service';

export interface FormModel {
  captcha?: string;
}


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() overlay: boolean; //Boolean value to open and close the overlay coming from footer component
  @Output() noOverlay = new EventEmitter<boolean>(); // Send an event with a boolean value to close de overlay
  @ViewChild ('main') main: HTMLElement;

  public contactForm: FormGroup;
  public hasError: boolean = false;
  public loader: boolean = false;
  public successAlert: boolean = false;
  public errorAlert: boolean = false;
  public siteKey: string = "6LdXhT4aAAAAALqfIiLb1Jqj3mJzi-1npzduDrY4"
  public captchaError: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private mailService: EmailService,
  ) {

    this.contactForm = this.formBuilder.group({
      name: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$")]],
      phone: ["", Validators.required],
      enterprise: ["", Validators.required],
      country: ["", Validators.required],
      message: ["", Validators.required],
      captcha: ["", Validators.required]
    })
  }

  ngOnInit(): void {
    this.main.addEventListener('touchmove', (e) => {{
      e.preventDefault();
    }})
  }

  closeOverlay() {
    this.noOverlay.emit(false);
  }

  loaderTest() {
    this.loader = true;
  }

  public resolved(captchaResponse: string): void {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.contactForm.controls.captcha.setValue(true)
    this.captchaError = false;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaError = true;
    // console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  // ///////////////////
  // ///////////
  // Email

  async register() {
    // console.log(this.contactForm.value);
    this.errorAlert = false;
    this.successAlert = false;
    console.log(this.contactForm.controls.captcha.value);

    if (!this.contactForm.valid) {
      this.hasError = true;
    } else {
      this.hasError = false;
      this.loader = true

      const response = await this.mailService.sendEmail(this.contactForm.value)

      if (response) {
        this.successAlert = true;
        this.loader = false;
        this.contactForm.reset();
      } else {
        this.errorAlert = true;
        this.loader = false;
      }
    }
  }

}
