import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbButtonModule, NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgwWowModule } from 'ngx-wow';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { HeaderComponent } from './components/header/header.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ServicesComponent } from './components/services/services.component';
import { PruebaComponent } from './components/prueba/prueba.component';
import { NextStepComponent } from './components/next-step/next-step.component';
import { NgxParallaxScrollModule } from 'ngx-parallax-scroll';
import { CountUpModule } from 'ngx-countup';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './components/contact/phone-mask.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { ScrollableTabsComponent } from './components/about-us/scrollable-tabs/scrollable-tabs.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import localeMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { FormAppointmentComponent } from './components/form-appointment/form-appointment.component';
import { NgxParallaxModule } from '@yoozly/ngx-parallax';
import { ParallaxDirective } from './directives/parallax.directive';

registerLocaleData(localeMX);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeSliderComponent,
    AboutUsComponent,
    ImageZoomComponent,
    ProjectsComponent,
    ClientsComponent,
    ServicesComponent,
    PruebaComponent,
    NextStepComponent,
    FooterComponent,
    ContactComponent,
    PhoneMaskDirective,
    ScrollableTabsComponent,
    CustomCalendarComponent,
    CalendarComponent,
    AppointmentsComponent,
    FormAppointmentComponent,
    ParallaxDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NgwWowModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AnimateOnScrollModule.forRoot(),
    NbButtonModule,
    NgxParallaxScrollModule,
    CountUpModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    RecaptchaModule,
    NgxParallaxModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    PhoneMaskDirective
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
