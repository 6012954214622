import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpService) { }

  sendEmail(data){
    return new Promise<any>((resolve, reject) => {
      console.log('datos del usuario', data)
        let year = new Date().getFullYear();
        let customer = {
          name: data.name,
          last: data.lastName,
          email: data.email,
          phone: data.phone,
          enterprise: data.enterprise,
          country: data.country,
          message: data.message,
          year: year
        }
        console.log(customer);
        const headers = { 'Authorization': 'Bearer ai5kaWF6QGRhcHBlcnRlY2hub2xvZ2llcy5jb206REFwcGVyTWVuc2FqZWRlQXV0ZW50aWNhY2lvbjEyMzE=' }
        this.http.sendEmail('https://new-dapper-mail.herokuapp.com/sendmail', customer,{ headers }).subscribe(
          data => {
            let res: any = data;
            console.log(
              `👏 > 👏 > 👏 > 👏 successfully register and mail has been sent and the message `, res
            );
            resolve(true)
          },
          err => {
            console.log(err);
            resolve(false)
          }, () => {
    
          }
        );
    });
  }
}
