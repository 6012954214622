import { Component, Inject, OnInit } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public showMobileMenu: boolean = false;
  public isMobile: boolean = false;
  @Inject(PLATFORM_ID) private platformId: any
  constructor() { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // localStorage will be available: we can use it.
      var self = this;
      if (screen.width <= 768) {
        self.isMobile = true;
      } else {
        self.isMobile = false;
      }
    }
  }

}
