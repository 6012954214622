<div class="container-fluid padded-menu">
    <div class="text-center title">
        <h1 class="text-white">Nosotros</h1>
        <h3 class="txt-blue">Lo mejor de lo mejor</h3>
    </div>

    <div class="row mt-5 mb-5 menu">
        <h4 class="col-md-4 text-center tab-us" [ngClass]="{'text-white' : selected_what}"
            (click)="selected_what = true; selected_how = false; selected_why = false">¿Qué hacemos?</h4>
        <h4 class="col-md-4 text-center tab-us" [ngClass]="{'text-white' : selected_how}"
            (click)="selected_how = true; selected_what = false; selected_why = false">¿Cómo lo hacemos?</h4>
        <h4 class="col-md-4 text-center tab-us" [ngClass]="{'text-white' : selected_why}"
            (click)="selected_why = true; selected_what = false; selected_how = false">¿Por qué nosotros?</h4>
    </div>
    <svg class="svg-h" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" height="35px" fill="#1D1D1D"
        id="my-first-icon" viewBox="0 0 420.75 420.75" style="enable-background:new 0 0 420.75 420.75;"
        xml:space="preserve">
        <g>
            <g>
                <path fill="var(--color-1)" d="M210.375,153C177.862,153,153,177.862,153,210.375s24.862,57.375,57.375,57.375s57.375-24.862,57.375-57.375
            S242.888,153,210.375,153z M210.375,248.625c-21.038,0-38.25-17.213-38.25-38.25c0-21.038,17.212-38.25,38.25-38.25
            c21.037,0,38.25,17.212,38.25,38.25C248.625,231.412,231.412,248.625,210.375,248.625z" />
                <path fill="var(--color-2)" d="M210.375,76.5C135.788,76.5,76.5,135.788,76.5,210.375c0,74.588,59.288,133.875,133.875,133.875
            c74.588,0,133.875-59.287,133.875-133.875C344.25,135.788,284.963,76.5,210.375,76.5z M210.375,325.125
            c-63.112,0-114.75-51.638-114.75-114.75s51.638-114.75,114.75-114.75s114.75,51.638,114.75,114.75
            S273.487,325.125,210.375,325.125z" />
                <path fill="var(--color-3)" d="M210.375,0C93.712,0,0,93.712,0,210.375C0,327.037,93.712,420.75,210.375,420.75
            c116.662,0,210.375-93.713,210.375-210.375C420.75,93.712,327.037,0,210.375,0z M210.375,401.625
            c-105.188,0-191.25-86.062-191.25-191.25s86.062-191.25,191.25-191.25s191.25,86.062,191.25,191.25
            S315.562,401.625,210.375,401.625z" />
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
    <svg class="svg-h" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" fill="#1D1D1D"
        id="my-second-icon" viewBox="0 0 420.75 420.75" style="enable-background:new 0 0 420.75 420.75;"
        xml:space="preserve">
        <g>
            <g>
                <path fill="var(--color-1)" d="M210.375,153C177.862,153,153,177.862,153,210.375s24.862,57.375,57.375,57.375s57.375-24.862,57.375-57.375
            S242.888,153,210.375,153z M210.375,248.625c-21.038,0-38.25-17.213-38.25-38.25c0-21.038,17.212-38.25,38.25-38.25
            c21.037,0,38.25,17.212,38.25,38.25C248.625,231.412,231.412,248.625,210.375,248.625z" />
                <path fill="var(--color-2)" d="M210.375,76.5C135.788,76.5,76.5,135.788,76.5,210.375c0,74.588,59.288,133.875,133.875,133.875
            c74.588,0,133.875-59.287,133.875-133.875C344.25,135.788,284.963,76.5,210.375,76.5z M210.375,325.125
            c-63.112,0-114.75-51.638-114.75-114.75s51.638-114.75,114.75-114.75s114.75,51.638,114.75,114.75
            S273.487,325.125,210.375,325.125z" />
                <path fill="var(--color-3)" d="M210.375,0C93.712,0,0,93.712,0,210.375C0,327.037,93.712,420.75,210.375,420.75
            c116.662,0,210.375-93.713,210.375-210.375C420.75,93.712,327.037,0,210.375,0z M210.375,401.625
            c-105.188,0-191.25-86.062-191.25-191.25s86.062-191.25,191.25-191.25s191.25,86.062,191.25,191.25
            S315.562,401.625,210.375,401.625z" />
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
</div>



<div class="mt-5 mb-5 tabs">
    <scrollable-tabs [tabs]="tabs" (emitSelectedTab)="selectedTab($event)"></scrollable-tabs>
</div>


<!-- How we do it -->
<div class="container-fluid mt-5 mb-5 padded-container wow animate__animated animate__fadeIn" *ngIf="selected_how">
    <div class="content-1 row">
        <div class="col-12 col-lg-6 first">
            <h2>¿Cuál es nuestro secreto?</h2>
            <p>En Dapper Technologies sumamos el talento de nuestro equipo multidisciplinario
                a nuestra vasta experiencia, brindando soluciones a clientes de diferentes giros
                e industrias, lo cual nos ha permitido desarrollar la mejor capacidad para asesorar
                de manera precisa y acertada a nuestros clientes por más de cinco años.
            </p>
            <a href="#contact">Conócelo por ti mismo</a>
        </div>
        <div class="col-6 no-mobile">
            <ul>
                <li class="row mb-3">
                    <!-- <nb-icon class="col-md-2" icon="radio-button-on"></nb-icon> -->
                    <svg class="icon icon-colors">
                        <use xlink:href="#my-first-icon" />
                    </svg>
                    <div class="dots-line1"></div>
                    <div class="col-md-10 point-text">
                        <h5>Levantamiento de Requerimientos</h5>
                        <p>Comenzamos con un profundo estudio y análisis de los procesos, y
                            necesidades de tu empresa, para poder presentarte una propuesta
                            de la solución ideal para ti.
                        </p>
                    </div>
                </li>
                <li class="row mb-3">
                    <!-- <nb-icon class="col-md-2" icon="radio-button-on"></nb-icon> -->
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg class="icon icon-colors">
                        <use xlink:href="#my-first-icon" />
                    </svg>
                    <div class="dots-line2"></div>
                    <div class="col-md-10 point-text">
                        <h5>Diseño y Prototipo</h5>
                        <p>Iniciamos el proyecto con un diseño funcional para asegurarnos
                            que el desarrollo sea la solución que imaginas.
                        </p>
                    </div>
                </li>
                <li class="row mb-3">
                    <!-- <nb-icon class="col-md-2" icon="radio-button-on"></nb-icon> -->
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg class="icon icon-colors">
                        <use xlink:href="#my-first-icon" />
                    </svg>
                    <div class="dots-line3"></div>
                    <div class="col-md-10 point-text">
                        <h5>Desarrollo del Proyecto</h5>
                        <p>Nuestro equipo de ingenieros hace realidad el proyecto, usando
                            las tecnologíasmás vanguardistas garantizando la mejor calidad.</p>
                    </div>
                </li>
                <li class="row mb-3">
                    <!-- <nb-icon class="col-md-2" icon="radio-button-on"></nb-icon> -->
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg class="icon icon-colors">
                        <use xlink:href="#my-first-icon" />
                    </svg>
                    <div class="col-md-10 point-text">
                        <h5>Revisión y Entrega</h5>
                        <p>Bienvenido al futuro de tu empresa.</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-12 mb-5 is-mobile">
            <!-- <nav>
                <ul>
                    <li><a href="#">Paso 1</a></li>
                    <li><a href="#">Paso 2</a></li>
                    <li><a href="#">Paso 3</a></li>
                    <li><a href="#">Paso 4</a></li>
                </ul>
            </nav> -->
            <div class="steps">
                <div class="col-12 line"></div>
                <div class="col mobile-steps" (click)="getStepToShow(1)" >
                    <svg class="icon" [ngClass]="(stepToShow == 1)?'icon-colors':'icon-gray'">
                        <use xlink:href="#my-first-icon" *ngIf="stepToShow == 1"/>
                        <use xlink:href="#my-second-icon" *ngIf="stepToShow != 1"/>
                    </svg>
                </div>
                <!-- <hr> -->
                <div class="col mobile-steps" value="2" (click)="getStepToShow(2)">
                    <svg class="icon" [ngClass]="(stepToShow == 2)?'icon-colors':'icon-gray'">
                        <use xlink:href="#my-first-icon" *ngIf="stepToShow == 2"/>
                        <use xlink:href="#my-second-icon" *ngIf="stepToShow != 2"/>
                    </svg>
                </div>
                <!-- <hr> -->
                <div class="col mobile-steps" value="3" (click)="getStepToShow(3)">
                    <svg class="icon" [ngClass]="(stepToShow == 3)?'icon-colors':'icon-gray'">
                        <use xlink:href="#my-first-icon" *ngIf="stepToShow == 3"/>
                        <use xlink:href="#my-second-icon" *ngIf="stepToShow != 3"/>
                    </svg>
                </div>
                <!-- <hr> -->
                <div class="col mobile-steps" value="4" (click)="getStepToShow(4)">
                    <svg class="icon" [ngClass]="(stepToShow == 4)?'icon-colors':'icon-gray'">
                        <use xlink:href="#my-first-icon" *ngIf="stepToShow == 4"/>
                        <use xlink:href="#my-second-icon" *ngIf="stepToShow != 4"/>
                    </svg>
                </div>
            </div>
            <div class="col-12">
                <div class="step-text wow animate__animated animate__fadeIn" *ngIf="stepToShow == 1">
                    <span>Paso 1</span>
                    <h5>Levantamiento de Requerimientos</h5>
                    <p  >Comenzamos con un profundo estudio y análisis de los procesos, y
                        necesidades de tu empresa, para poder presentarte una propuesta
                        de la solución ideal para ti.
                    </p>
                </div>
                <div class="step-text wow animate__animated animate__fadeIn" *ngIf="stepToShow == 2">
                    <span>Paso 2</span>
                    <h5>Diseño y Prototipo</h5>
                    <p >Iniciamos el proyecto con un diseño funcional para asegurarnos
                        que el desarrollo sea la solución que imaginas.
                    </p>
                </div>
                <div class="step-text wow animate__animated animate__fadeIn" *ngIf="stepToShow == 3">
                    <span>Paso 3</span>
                    <h5>Desarrollo del Proyecto</h5>
                    <p >Nuestro equipo de ingenieros hace realidad el proyecto, usando
                        las tecnologíasmás vanguardistas garantizando la mejor calidad.
                    </p>
                </div>
                <div class="step-text wow animate__animated animate__fadeIn" *ngIf="stepToShow == 4">
                    <span>Paso 4</span>
                    <h5>Revisión y Entrega</h5>
                    <p >Bienvenido al futuro de tu empresa.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- What we do -->
<div class="container-fluid mt-5 mb-5 padded-container wow animate__animated animate__fadeIn" *ngIf="selected_what">
    <div class="content-1 row">
        <div class="col-12 col-md-4 what-left">
            <p>Aquí en...</p>
        </div>
        <div class="col-12 col-md-8 what-right">
            <p>Dapper Technologies, nos especializamos en el análisis de procesos administrativos,
                operativos y/o comerciales, para el desarrollo de soluciones de automatización y
                optimización que de la mano de estrategias digitales de marketing, generen un
                incremento directo en las utilidades de nuestros clientes.</p>
        </div>
    </div>
    <div class="col-12 what-visuals">
        <div class="row">
            <div class="col-3 what-markers">
                <svg class="icon icon-colors">
                    <use xlink:href="#my-first-icon" />
                </svg><hr>
                <p>Analizamos tus procesos</p>
            </div>
            <div class="col-3 what-markers">
                <svg class="icon icon-colors">
                    <use xlink:href="#my-first-icon" />
                </svg><hr>
                <p>Detectamos tus necesidades</p>
            </div>
            <div class="col-3 what-markers">
                <svg class="icon icon-colors">
                    <use xlink:href="#my-first-icon" />
                </svg><hr>
                <p>Desarrollamos tu solución</p>
            </div>
            <div class="col-3 what-markers">
                <svg class="icon icon-colors">
                    <use xlink:href="#my-first-icon" />
                </svg><hr class="noShow">
                <p>Incrementamos tus utilidades</p>
            </div>
        </div>
    </div>
    <div class="what-visuals-mobile">
        <img src="../../../assets/images/what-lines.png" alt="">
    </div>
</div>

<!-- Why us -->
<div class="container-fluid mt-5 mb-5 padded-container wow animate__animated animate__fadeIn" *ngIf="selected_why">
    <div class="content-1">
        <div class="col-12 why-title">
            <h4>Hacemos lo que sea necesario</h4>
        </div>
        <div class="col-12 why-text">
            <p>En Dapper Technologies lo más importante es brindar resultados a nuestros clientes,
                a través de nuestras soluciones y servicios. Es por esto que nuestra filosofía es
                volvernos parte de tu equipo y de tu empresa, convirtiendo tus metas y objetivos,
                en nuestros.</p>
        </div>
        <div class="container why-extras">
            <div class="row">
                <div class="col-6 extra-left">
                    <h1 [countUp]="93" id="proyects-count">0</h1>
                    <p>Proyectos Exitosos</p>
                </div>
                <div class="col-6 extra-right">
                    <h1 [countUp]="5" id="years-count">0</h1>
                    <p>Años de experiencia</p>
                </div>
            </div>
        </div>
    </div>
</div>