import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarService } from 'src/app/services/calendar/calendar.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  @Input() date: any; 
  @Output() noOverlay = new EventEmitter<boolean>(); 
  @ViewChild('content', { static: false }) private content;
  public calendars = [];
  public date_info = [];
  public available_dates = [];

  // date;

  schedules = true;
  client = false;
  confirmation = false;
  form = false;
  date_selected;
  id;
  calId;

  dateStr;

  locale: string = 'en';
  schedule = new Map();

  //forms
  dateForm:FormGroup;
  recepName = "XUwmWzsRMkj1mWVHTkhk";

  constructor(
    private afs: AngularFirestore,
    private formBuilder: FormBuilder,
    public calendarService: CalendarService) {
    this.getCalendars();
    this.dateForm = this.formBuilder.group({
      user: ['', Validators.required],
      email: ["", [Validators.required, Validators.pattern("^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$")]],
      phone: ["", Validators.required],
      comments: ['']
    });
  }

  ngOnInit(): void {
    this.calendarService.flag = "two";
    console.log(this.date);
    this.getDate();
  }

  getDate() {
    var newDate = new Date(this.date);

    this.dateStr = newDate.toLocaleDateString('es-MX', { weekday: "long", year: "numeric", month: "long", day: "numeric" });
  }

  async getCalendars() {
    var calendars = await this.calendarService.getCalendars();
    for (let index = 0; index < calendars.length; index++) {
      const calendar = calendars[index];
      this.calendars.push(calendar);
      var dates = await this.calendarService.getSchedules(calendar.id, this.date)
      this.available_dates = [];
      for (let i = 0; i < dates.length; i++) {
        const date = dates[i];
        var now = new Date(Date.now())
        now.setMilliseconds(0)
        var complete = new Date(date.start)

        if (complete > now) {
          this.available_dates.push(date)
        }
      }

      this.schedule.set(calendar.id,
        {
          name: calendar.name,
          schedules: this.available_dates,
          calId: calendar.id
        });
    }
  }

  goToDate(data) {
    this.date_selected = data
    this.schedules = false;
    this.form = true;
  }

  redirect() {
    this.noOverlay.emit(false);
  }

  closeOverlay(item: boolean){
    this.form = item;
    this.schedules = !item;
  }

  closeAll(item: boolean){
    this.form = item;
    this.schedules = item;
    this.noOverlay.emit(false);
  }

}
